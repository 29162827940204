import * as React from 'react'

import Layout from "../../components/layout";
import Seo from "../../components/seo";
import {graphql, Link} from "gatsby";

export const query = graphql`
    query {
        allMdx(sort: {fields: frontmatter___date, order: DESC}) {
            nodes {
                frontmatter {
                    date(formatString: "MMMM D, YYYY")
                    title
                    slug
                }
                id
                excerpt
            }
        }
    }
`
const BlogPage = ({ data }) => {
    return (
        <Layout pageTitle="Blog posts">
            <p>Blog posts are gonna be here</p>
            {
                data.allMdx.nodes.map(node => (
                    <article key={node.id}>
                        <h2 className="text-primary-600"><Link to={`/blog/${node.frontmatter.slug}`}>{node.frontmatter.title}</Link></h2>
                        <p>Posted: {node.frontmatter.date}</p>
                    </article>
                ))
            }
        </Layout>
    )
}

export const Head = () => <Seo title="Blog posts" />

export default BlogPage